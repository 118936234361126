import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MeetupInvestigator from './components/MeetupInvestigator';
import Meetup from './components/Meetup';

function App() {
  return (
    <Router>
      <div className="App">
        <MeetupInvestigator />
        <Routes>
          <Route path="/meetup/:guid" element={<Meetup />} /> {/* Add the new route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
