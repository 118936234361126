import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './MeetupInvestigator.module.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { fetchFromApi } from '../utils/api';

const MeetupInvestigator = () => {
  const [guid, setGuid] = useState('');
  const [isFetchingUrl, setIsFetchingUrl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [meetupUrl, setMeetupUrl] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeetupUrl = async (guid: any) => {
      setIsFetchingUrl(true);
      try {
        const response = await fetchFromApi(`/meetup-url/${guid}`);
        if (response.status === 200) {
          const data = await response.json();
          setIsFetchingUrl(false);
          setMeetupUrl(data.meetupUrl);
        } else {
          setIsFetchingUrl(false);
          setError('Failed to fetch meetup URL');
        }
      } catch (error) {
        setIsFetchingUrl(false);
        console.error('Error fetching from API:', error);
        setError('An error occurred while fetching data.');
      }
    };

    // Extract the GUID from the URL
    const path = window.location.pathname; // e.g., "/meetup/12345"
    const match = path.match(/\/meetup\/(.+)/); // Regex to extract GUID
    if (match && match[1]) {
      console.log('fetching meetup url for guid:', match[1]);
      fetchMeetupUrl(match[1]);
    } else {
      console.log('no guid found in URL');
    }
  }, []); // Empty dependency array means this runs once on mount

  const validateUrl = (url: string) => {
    const regex = /^(https:\/\/www\.meetup\.com\/)([^\/]+\/events\/)(\d+)\/?$/;
    return regex.test(url);
  };

  const generateGUID = () => {
    // Generate current timestamp in yyyymmddhhmmss format
    const now = new Date();
    const timestamp = now.getFullYear().toString() +
      (now.getMonth() + 1).toString().padStart(2, '0') +
      now.getDate().toString().padStart(2, '0')

    // Generate a random sequence of 8 hexadecimal characters
    const randomSequence = 'xxxxxxxx'.replace(/[x]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      return r.toString(16);
    });

    // Combine the timestamp with the random sequence
    return `${timestamp}-${randomSequence}`;
  };

  const initiateSearch = async () => {
    if (meetupUrl === '') {
      setError('');
      setIsLoading(false);
      return;
    }

    if (!validateUrl(meetupUrl)) {
      setError('Please enter a valid Meetup URL.');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const newGuid = generateGUID();
    try {
      navigate(`/meetup/${newGuid}`);
      const response = await fetchFromApi('/scrape', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          guid: newGuid,
          meetupUrl,
        }),
      });

      if (response.status === 200) {
        setIsLoading(false);
      } else {
        const data = await response.json();
        setError(data.message || 'Failed to start scraping');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching from API:', error);
      setError('An error occurred while fetching data.');
      setIsLoading(false);
    }
  };

  return (
    <header className="App-header text-center">
      <h1 className={styles.title}><img src="/logo512.png" width={36} style={{ position: 'relative', top: '-4px' }} /> Meetup Investigator</h1>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-9">
            <div className={`input-group mt-3 ${styles.inputGroup}`}>
              <input
                type="text"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                placeholder={isFetchingUrl ? "Loading..." : "Paste meetup.com event URL here"}
                aria-label="Meetup URL"
                aria-describedby="button-addon2"
                value={meetupUrl}
                onChange={(e) => setMeetupUrl(e.target.value)}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                disabled={isLoading}
                onClick={initiateSearch}
              >
                Search
              </button>
            </div>
            <div style={{ minHeight: '50px' }}>
              {error && (
                <div className="invalid-feedback" style={{ display: 'block' }}>{error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default MeetupInvestigator;
