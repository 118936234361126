import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchFromApi, fetchFromS3 } from '../utils/api';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

// Assuming fetchFromS3 is properly typed in your utils/api file
// Define an interface for attendee information
interface Attendee {
  guid: string;
  key: string;
  name: string;
  title?: string | null;
  linkedin_url: string;
  company_name: string;
  company_key: string;
}

// Define an interface for the meetup details
interface MeetupDetails {
  guid: string;
  meetupUrl: string;
  name: string;
  date: string;
  location: string;
  description: string;
  scrapedAttendees: number;
  totalAttendees: number;
  lastModified: string;
  ageSeconds: number;
  topics: string[];
  attendees: Record<string, string>; // Map of attendee key to signed URL
}

const Meetup = () => {
  const { guid } = useParams<{ guid: string }>();
  const [meetupDetails, setMeetupDetails] = useState<MeetupDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [attendeeData, setAttendeeData] = useState<Record<string, Attendee>>({});
  const [loadingStatus, setLoadingStatus] = useState<string>('loading');
  const [fetchAttempt, setFetchAttempt] = useState<number>(0);

  // If guid changes, reset the state
  useEffect(() => {
    setMeetupDetails(null);
    setIsLoading(true);
    setError('');
    setAttendeeData({});
    setLoadingStatus('loading');
    setFetchAttempt(0);
  }, [guid]);

  useEffect(() => {
    const fetchMeetupDetails = async () => {
      if (loadingStatus === 'done' || fetchAttempt >= 100) {
        setIsLoading(false); // Stop loading if done or max attempts reached
        return;
      }

      try {
        const response = await fetchFromApi(`/meetup/details/${guid}`);
        if (!response.ok) throw new Error('Failed to fetch meetup details');
        const data: MeetupDetails = await response.json();

        setMeetupDetails(data);
        // Determine the loading status based on the response data
        if (data.name === '') {
          setLoadingStatus('crawling-meetup');
        } else if (data.scrapedAttendees === 0) {
          setLoadingStatus('crawling-attendees-started');
        } else if (data.scrapedAttendees > 0 && data.scrapedAttendees < data.totalAttendees) {
          setLoadingStatus('crawling-attendees');
          fetchAttendeeDetails(data.attendees);
        } else if (data.scrapedAttendees === data.totalAttendees) {
          setLoadingStatus('done');
          if (Object.keys(attendeeData).length === 0) {
            fetchAttendeeDetails(data.attendees);
          }
          setIsLoading(false); // Data fully loaded
        }
      } catch (err: any) {
        setError(err.message);
        setIsLoading(false);
      }

      setFetchAttempt(fetchAttempt + 1);
    };

    // Set an interval to fetch details every 3 seconds
    const intervalId = setInterval(() => {
      fetchMeetupDetails();
    }, 3000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [guid, fetchAttempt, loadingStatus]); // Include fetchAttempt and loadingStatus in the dependency array

  const fetchAttendeeDetails = async (attendees: Record<string, string>) => {
    const attendeeKeys = Object.keys(attendees);
    attendeeKeys.forEach(async (attendeeKey) => {
      if (!attendeeData[attendeeKey]) {
        try {
          const url = attendees[attendeeKey];
          const response = await fetchFromS3(url);
          if (!response.ok) throw new Error(`Failed to fetch data for ${attendeeKey}`);
          const attendeeInfo: Attendee = await response.json();
          setAttendeeData(prevData => ({ ...prevData, [attendeeKey]: attendeeInfo }));
        } catch (err: any) {
          // console.log(attendeeKey)
          // console.error(err.message);
        }
      }
    });
  };

  const progressValue = meetupDetails ? (meetupDetails.ageSeconds < 120 ? meetupDetails.scrapedAttendees / meetupDetails.totalAttendees : 1) : 0;

  const meetupDetailsTable = meetupDetails && meetupDetails.topics && <table className="table mb-4">
    <tbody>
      <tr>
        <th style={{ textAlign: 'left', width: '20%' }}>Name</th>
        <td style={{ textAlign: 'left' }}>{meetupDetails.name}</td>
      </tr>
      <tr>
        <th style={{ textAlign: 'left' }}>Topics</th>
        <td style={{ textAlign: 'left' }}>
          {meetupDetails.topics.map((topic, index) => (
            <span key={index} className="badge bg-secondary" style={{ borderRadius: '20px', marginRight: '10px' }}>
              {topic}
            </span>
          ))}
        </td>
      </tr>
      <tr>
        <th style={{ textAlign: 'left' }}>Progress</th>
        <td style={{ textAlign: 'left' }}>
          <div className="progress" style={{ width: '100%', height: '20px' }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${(progressValue) * 100}%` }}
              aria-valuenow={meetupDetails.scrapedAttendees}
              aria-valuemin={0}
              aria-valuemax={meetupDetails.totalAttendees}>
              {Math.round((progressValue) * 100)}%
            </div>
          </div>
          {meetupDetails.ageSeconds < 120 && <div style={{ marginTop: '5px', color: '#aaa' }}>
            {loadingStatus !== 'done' && fetchAttempt < 100 && meetupDetails.ageSeconds < 120 && <span className="spinner-border spinner-border-sm" role="status" style={{ marginRight: '7px', color: '#aaa' }}></span>}
            {meetupDetails.scrapedAttendees} / {meetupDetails.totalAttendees} Attendees crawled
          </div>}
          {meetupDetails.ageSeconds >= 120 && <div style={{ marginTop: '5px', color: '#aaa' }}>
            {meetupDetails.totalAttendees} / {meetupDetails.totalAttendees} Attendees crawled
          </div>}
        </td>
      </tr>
    </tbody>
  </table>

  const attendeesTable = <>
    <table className="table">
      <thead className="thead-light">
        <tr>
          <th style={{ textAlign: 'left' }}>Name</th>
          <th style={{ textAlign: 'left' }}>Company Name</th>
          {/* Only show title if there are non null titles in the attendees */}
          <th style={{ textAlign: 'left' }}>Title</th>
          <th style={{ textAlign: 'left' }}>LinkedIn</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(attendeeData)
          .sort((a, b) => {
            // Determine the presence of company_key and linkedin_url for both attendees
            const aHasBoth = a.company_key.length > 0 && a.linkedin_url.length > 0;
            const bHasBoth = b.company_key.length > 0 && b.linkedin_url.length > 0;
            const aHasLinkedInOnly = a.company_key.length === 0 && a.linkedin_url.length > 0;
            const bHasLinkedInOnly = b.company_key.length === 0 && b.linkedin_url.length > 0;

            // Sort by those who have both company_key and LinkedIn URL first
            if (aHasBoth && !bHasBoth) return -1;
            if (!aHasBoth && bHasBoth) return 1;

            // Among those who do not have both, sort those who have only LinkedIn URL next
            if (aHasLinkedInOnly && !bHasLinkedInOnly) return -1;
            if (!aHasLinkedInOnly && bHasLinkedInOnly) return 1;

            // Finally, sort alphabetically by name as a tertiary sort
            return a.name.localeCompare(b.name);
          })
          .map((attendee, index) => (
            <tr key={attendee.key}>
              <td style={{ textAlign: 'left' }}>{attendee.name}</td>
              <td style={{ textAlign: 'left' }}>
                {attendee.company_name.length > 0 && attendee.company_name !== "N/A" && <>
                  <img src={`https://leadray-company-logos.s3.eu-west-1.amazonaws.com/${attendee.company_key}.png`} width={22} />
                  <span style={{ paddingLeft: '10px', position: 'relative', top: '2px' }}>{attendee.company_name}</span>
                </>}
                {(attendee.company_name.length === 0 || attendee.company_name === "N/A") && <span style={{ color: '#ccc' }}>N/A</span>}
              </td>
              {attendee.title && attendee.title.length > 0 && attendee.title !== "N/A" && (
                <td style={{ textAlign: 'left' }}>
                  {attendee.title.length > 40 ? attendee.title.substring(0, 40) + '...' : attendee.title}
                </td>
              )}
              {(!attendee.title || attendee.title.length === 0 || attendee.title === "N/A") && (
                <td style={{ textAlign: 'left' }}><span style={{ color: '#ccc' }}>N/A</span></td>
              )}
              <td style={{ textAlign: 'left' }}>
                {attendee.linkedin_url.length > 0 && <a href={attendee.linkedin_url} target="_blank" rel="noopener noreferrer">LinkedIn</a>}
                {attendee.linkedin_url.length === 0 && <span style={{ color: '#ccc' }}>N/A</span>}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </>

  return (
    <div className="container" style={{ maxWidth: '1024px', margin: '0 auto' }}> {/* Adjusted for narrower width with more margin */}
      {error && <p className="text-danger">Error: {error}</p>}
      {loadingStatus === 'loading' && (
        <div><span className="spinner-border spinner-border-sm" role="status" style={{ marginRight: '7px' }}></span>Loading...</div>
      )}
      {loadingStatus === 'crawling-meetup' && (
        <>
          <div><span className="spinner-border spinner-border-sm" role="status" style={{ marginRight: '7px' }}></span>Crawling meetup, please wait...</div>
        </>
      )}
      {loadingStatus === 'crawling-attendees-started' && (
        <>
          {meetupDetailsTable}
          <div><span className="spinner-border spinner-border-sm" role="status" style={{ marginRight: '7px' }}></span>Crawling attendees...</div>
        </>
      )}
      {(loadingStatus === 'crawling-attendees' || loadingStatus === 'done') && (
        <>
          {meetupDetailsTable}
          {attendeesTable}
        </>
      )}
      {error && <p className="text-danger">Error: {error}</p>}
    </div>
  );
};

export default Meetup;

